




























import { defineComponent } from '@nuxtjs/composition-api';
import SfCarousel from '~/components/StorefrontUi/SfCarousel.vue';

export default defineComponent({
  components: { SfCarousel },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {};
  },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SfCarousel',{staticClass:"header-slider h-12 lg:h-8 flex justify-center w-full",attrs:{"arrows":false,"dots":false,"settings":{
    type: 'slider',
    rewind: true,
    perView: 1,
    gap: 0,
    autoplay: 4000,
    breakpoints: {
      1023: {
        perView: 1,
        gap: 0,
      },
    },
  }}},_vm._l((_vm.blok.HeaderSlide),function(slide){return _c('SfCarouselItem',{key:slide._uid},[_c('StoryblokComponent',{attrs:{"blok":slide}})],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }